import styled from "styled-components"
import device from "particles/mediaQueries"

const StyledPost = styled.article`
  a {
    color: ${(props) => props.theme.primary};
    transition: 0.2s all ease;

    &::after {
      display: none;
    }

    &:active,
    &:focus,
    &:hover {
      color: ${(props) => props.theme.secondary};
      text-decoration: none;
    }

    &:focus {
      outline: 1px solid ${(props) => props.theme.primary};
      outline-offset: 5px;
    }
  }

  code,
  pre {
    background: none;
    color: ${(props) => props.theme.white};
    font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    font-size: 1em;
    hyphens: none;
    line-height: 1.5;
    tab-size: 4;
    text-align: left;
    text-shadow: 0 -0.1em 0.2em ${(props) => props.theme.black};
    white-space: pre;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;

    ::-webkit-scrollbar {
      background-color: ${(props) => props.theme.grey300};
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${(props) => props.theme.grey600};
      border: 2px solid ${(props) => props.theme.grey600};
    }

    a {
      color: inherit;
    }
  }

  @media print {
    code,
    pre {
      text-shadow: none;
    }
  }

  pre,
  :not(pre) > code {
    background: ${(props) => props.theme.black};
  }

  /* Code blocks */
  pre {
    padding: 1em;
    margin: 32px 0;
    overflow: auto;
    border: 0.3em solid ${(props) => props.theme.black};
    border-radius: 0.5em;
    box-shadow: 1px 1px 0.5em black inset;
  }

  /* Inline code */
  :not(pre) > code {
    padding: 0.15em 0.2em 0.05em;
    border-radius: 0.3em;
    border: 0.13em solid hsl(0, 0%, 11%);
    box-shadow: none;
    white-space: normal;
  }

  .component-wrapper {
    margin: 96px 0;

    @media ${device.md} {
      margin: 128px 0;
    }

    @media ${device.xl} {
      margin: 156px 0;
    }

    @media ${device.xxl} {
      margin: 196px 0;
    }
  }

  .wp-block-image {
    margin-left: 0;
    margin-right: 0;
    margin-top: 32px;

    a:first-of-type {
      &::after {
        display: none;
      }
    }

    img {
      display: block;
      margin: 0 auto;
    }
  }

  .component-wrapper__contents {
    margin: 0 auto;
    max-width: 880px;
  }

  .component-wrapper__contents {
    font-size: 2rem;

    @media ${device.md} {
      font-size: 2.4rem;
    }
  }
`

export default StyledPost
